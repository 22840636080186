import React, { FC, useEffect, useState } from 'react';
import './PopularTechnique.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import ModalContact from '../../../Pages/ModalContact/ModalContact';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import greyBg from '../../../assets/Main_images/grey.jpg';
import { fetchByAllTechmodel } from '../../../store/slice/specialEquipmentSlice';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PopularTechnique: FC = () => {
    const { special } = useAppSelector(state => state.special);
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const [enter, setEnter] = useState(false);

    useEffect(() => {
        dispatch(fetchByAllTechmodel());
    }, [dispatch]);

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: window.screen.width <= 600 ? 1 : window.screen.width <= 920 ? 2 : 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseHover: true,
        arrows: window.screen.width <= 768 ? false : true
    };

    const lang = i18n.language;

    return (
        <div className='PopularTechnique'>
            <h2 className='PopularTechniqueTitle'>{t('popularCard.title')}</h2>
            <div className='slider-container'>
                <Slider {...settings}>
                    {special?.length > 0 &&
                        special.slice(0, 10).map(el => (
                            <div key={el.id}>
                                <Link to={`/detail-equipment/${el.id}`} className='PopularTechniqueCard'>
                                    <div className='PopularTechniqueCardName'>
                                        <h2 className='PopularTechniqueName'>{lang === 'RU' ? el.type_name_RU?.title_RU : el.type_name_CHS?.title_CHS}</h2>
                                        <h3 className='PopularTechniqueText'>{lang === 'RU' ? el.name_RU?.length > 15 ? el.name_RU?.slice(0, 15) + '...' : el.name_RU : el.name_CHS?.length > 15 ? el.name_CHS?.slice(0, 15) + '...' : el.name_CHS}</h3>
                                    </div>
                                    <div className='PopularTechniqueCardImg'>
                                        <img className='animate__animated animate__fadeInLeft' src={el.photo ? el.photo : greyBg} alt='pt' />
                                    </div>
                                    <div className='PopularTechniqueCardPrice'>
                                        <h4 className='PopularTechniquePrice'>$ {el.price}</h4>
                                        <button onClick={() => setEnter(!enter)} className='PopularTechniqueBtn'>{t('popularCard.btnorder')}</button>
                                    </div>
                                </Link>
                            </div>
                        ))
                    }
                </Slider>
            </div>
            {enter && <ModalContact enter={enter} setEnter={setEnter} />}
        </div>
    );
};

export default PopularTechnique;
